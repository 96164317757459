import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/modules/app-material.module';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from 'src/app/components/layout/notification-dialog/notification-dialog.component';
import { SwPush } from '@angular/service-worker';
import { NotificationService } from 'src/app/shared/services/notifications.service';
import {
  Notification,
  NOTIFICATION_PERMISSION_DEFAULT,
  NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY,
  NotificationOriginType,
} from 'src/app/models/notification';
import { SnackbarService } from 'src/app/shared/utils/snackbar/snackbar.service';
import { TitleService } from 'src/app/shared/services/title.service';

@Component({
  selector: 'app-notification-popup',
  standalone: true,
  imports: [CommonModule, RouterModule, AppMaterialModule],
  templateUrl: './notification-popup.component.html',
  styleUrl: './notification-popup.component.scss',
})
export class NotificationPopupComponent implements OnInit, OnDestroy {
  @ViewChild('notificationSound') notificationSound: ElementRef;

  swPush = inject(SwPush);
  router = inject(Router);
  titleService = inject(TitleService);

  notificationService = inject(NotificationService);
  snackbarService = inject(SnackbarService);

  notifications: Notification[] = [];
  page: number = 1;
  isFetching: boolean = false;
  limitReached: boolean = false;

  unreadNotifications: Notification[] = [];
  unreadCount: number = 0;

  intervalRef: any;

  constructor() {
    this.swPush.notificationClicks.subscribe((event) => {
      const { url } = event.notification.data;
      this.openNotificationUrl(url);
    });
  }

  readonly dialog = inject(MatDialog);
  sortedNotifications$: Observable<any>;
  pushNotificationsGranted: boolean = false;

  ngOnInit() {
    this.fetchUnreadNotifications(true);
    this.fetchNotifications();
    this.intervalRef = setInterval(() => {
      this.fetchUnreadNotifications();
    }, 60000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalRef);
  }

  shouldAskForPermission() {
    const localStoragePermission = localStorage.getItem(
      NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY
    );
    const notificationPermission = Notification.permission;

    if (
      localStoragePermission === null &&
      notificationPermission === NOTIFICATION_PERMISSION_DEFAULT
    ) {
      this.dialog.open(NotificationDialogComponent, {
        minWidth: '300px',
        width: '40%',
      });
    }
  }

  markAsRead(notification: Notification) {
    this.openNotificationUrl(notification.url);
    this.notificationService.markAsRead(notification.id).subscribe({
      next: (data) => {
        notification.hasRead = true;
        this.notifications = [notification, ...this.notifications];
        this.unreadNotifications = this.unreadNotifications.filter(
          (n) => n.id !== notification.id
        );
        this.unreadCount = this.unreadNotifications.length;
        this.titleService.setNotificationCount(this.unreadCount);
      },
      error: (error) => {
        console.error('Error marking notification as read:', error);
      },
    });
  }

  fetchUnreadNotifications(isInitialFetch: boolean = false) {
    this.notificationService.getUnreadNotifications().subscribe({
      next: (notifications) => {
        if (!isInitialFetch) {
          this.playNotificationSound(notifications.length);
        }
        this.unreadNotifications = notifications;
        this.unreadCount = notifications.length;
        this.titleService.setNotificationCount(this.unreadCount);
      },
      error: (error) => {
        console.error('Error fetching unread notifications:', error);
      },
    });
  }

  fetchNotifications() {
    this.isFetching = true;
    this.notificationService.getNotifications(this.page, 5).subscribe({
      next: (data) => {
        this.limitReached = data.pagination.totalPages === this.page;
        if (data.data.length > 0) {
          this.notifications = [...this.notifications, ...data.data];
          this.page++;
        }
        this.isFetching = false;
      },
      error: (error) => {
        this.snackbarService.open({
          type: 'error',
          message: 'Fehler beim Laden der Benachrichtigungen.',
        });
        this.isFetching = false;
      },
    });
  }

  openNotificationUrl(url: string) {
    try {
      const urlObj = new URL(url);
      if (urlObj.origin === window.location.origin) {
        console.log('Navigating to', urlObj.pathname);
        if (urlObj.search) {
          this.router.navigate([urlObj.pathname], {
            queryParams: urlObj.searchParams,
          });
          return;
        }
        this.router.navigate([urlObj.pathname]);
      } else {
        console.log('Opening new tab for', url);
        window.open(urlObj, '_blank');
      }
    } catch (e) {
      if (url.includes('?')) {
        const [path, query] = url.split('?');
        this.router.navigate([path], { queryParams: { query } });
        return;
      }
      this.router.navigate([url]);
    }
  }

  notificationOriginTypeToIcon(type: NotificationOriginType) {
    switch (type) {
      case NotificationOriginType.ORD:
        return 'assignment';
      case NotificationOriginType.DOC:
        return 'folder';
      case NotificationOriginType.MM:
        return 'local_offer';
      case NotificationOriginType.CTR:
        return 'history_edu';
      case NotificationOriginType.ADMIN:
        return 'admin_panel_settings';
      default:
        return 'notifications';
    }
  }

  playNotificationSound(newCount: number) {
    if (newCount > this.unreadNotifications.length) {
      this.notificationSound.nativeElement.play();
    }
  }

  // newNotifications() {
  //   this.notificationService.getNotifications(this.page + 1, 5).subscribe(
  //     (data) => {
  //       if (data.data.length <= 0) {
  //         this.snackbarService.open({
  //           type: 'warning',
  //           message: 'Keine weiteren Benachrichtigungen.',
  //         });
  //       } else {
  //         this.notifications.push(...data.data);
  //         this.pageCount = data.pageCount;
  //         this.sortedNotifications$ = this.getSortedNotifications();
  //         this.page++;
  //         this.countUnreadNotifications();
  //       }
  //     },
  //     (error) => {
  //       console.error('Error fetching notifications:', error);
  //     }
  //   );
  //   // Update sortedNotifications$
  // }
}
